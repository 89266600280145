import React from 'react';

import { GiBookCover } from 'react-icons/all';
import styled from '@emotion/styled';
import { containerCss } from '../utils/global-styles';
import { Tile, TileGridWrapper, TilleFiller } from '../components/tile';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { navigateToCalculator } from '../routing/routes';
import { PdcIntroduction } from '../pdc/components/pdc-introduction';
import { PdcDefinitions } from '../pdc/pdc-definitions';
import { ChTooltip } from '../components/ch-tooltip';

const calcs = [
  {
    name: 'Zbiorniki Ciśnieniowe',
    navigate: navigateToCalculator('zbiorniki'),
    icon: GiBookCover,
    label: PdcDefinitions.vessel,
  },
  {
    name: 'Rurociągi',
    navigate: navigateToCalculator('rurociagi'),
    label: PdcDefinitions.pipeline,
    icon: GiBookCover,
  },
  {
    name: 'Osprzęt Ciśnieniowy',
    navigate: navigateToCalculator('osprzet_cisnieniowy'),
    label: PdcDefinitions.pressureDevice,
    icon: GiBookCover,
  },
  {
    name: 'Urządzenia Zabezpieczające',
    navigate: navigateToCalculator('urzadzenie_zabezpieczajace'),
    label: PdcDefinitions.protectorDevice,
    icon: GiBookCover,
  },
];

const minCalcTileWidth = 300;

const CalcTile = styled(Tile)`
  white-space: nowrap;
  flex: 1 1 0;
  min-width: ${minCalcTileWidth}px;
`;

const CalcTileFiller = styled(TilleFiller)`
  flex: 1 1 0;
  min-width: ${minCalcTileWidth}px;
`;

const CalcsPage = () => (
  <Layout>
    <SEO title="Kalkulatory" />
    <Calcs></Calcs>
  </Layout>
);

export default CalcsPage;

const SectionStyled = styled.section`
  ${containerCss};
`;

const Calcs = () => (
  <SectionStyled>
    <PdcIntroduction></PdcIntroduction>
    <div e2e="calcs">
      <TileGridWrapper>
        {calcs.map((calc, index) => {
          return (
            <ChTooltip key={index} label={calc.label}>
              <CalcTile
                onClick={calc.navigate}
                isClickable={true}
                text={calc.name}
                Icon={calc.icon}
              ></CalcTile>
            </ChTooltip>
          );
        })}
        <CalcTileFiller></CalcTileFiller>
        <CalcTileFiller></CalcTileFiller>
        <CalcTileFiller></CalcTileFiller>
      </TileGridWrapper>
    </div>
  </SectionStyled>
);
