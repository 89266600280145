import React, { ReactNode, Ref } from 'react';
import styled from '@emotion/styled';
import { IconContext, IconType } from 'react-icons';
import { rhythm } from '../utils/typography';
import { css } from '@emotion/core';
import { colors } from '../utils/color-palette';

const clickableStyles = css`
  cursor: pointer;

  &:hover {
    color: ${colors.specialNav};
  }
`;

export const TilleFiller = styled.div`
  flex: 1 1 400px;
  margin: 0 ${rhythm(0.5)};
  padding: 0 ${rhythm(1)};
  height: 0;
`;

const TileWrapperStyled = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  margin: ${rhythm(0.5)};
  padding: ${rhythm(1)};
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  background-color: #f1f1f1;
  flex: 1 1 400px;

  p {
    margin-top: ${rhythm(1)};
  }

  .tile-icon {
    width: ${rhythm(2)};
    height: ${rhythm(2)};
  }
`;

const TileGridStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const TileFn = (
  {
    Icon,
    header,
    text,
    isClickable = false,
    onClick = () => undefined,
    className = '',
    ...props
  }: {
    Icon: IconType;
    header?: string;
    text: string;
    isClickable?: boolean;
    onClick?: () => void;
    className?: string;
  },
  ref: Ref<HTMLDivElement>
) => (
  <TileWrapperStyled
    ref={ref}
    className={className}
    onClick={onClick}
    css={isClickable ? clickableStyles : {}}
    {...props}
  >
    <IconContext.Provider value={{ className: 'tile-icon' }}>
      <Icon></Icon> {header && <h2>{header}</h2>} <p>{text}</p>
    </IconContext.Provider>
  </TileWrapperStyled>
);

export const Tile = React.forwardRef(TileFn);

export const TileGridWrapper = ({
  children,
  className,
}: {
  className?: string;
  children: ReactNode;
}) => <TileGridStyled className={className}>{children}</TileGridStyled>;
