import React from 'react';
import { DivWithGradient } from '../../components/paragraph-with-gradient';
import { css } from '@emotion/core';
import { adjustFontSizeTo, rhythm } from '../../utils/typography';
import styled from '@emotion/styled';
import { textColors } from '../../utils/color-palette';

const DocuLink = styled.a`
  text-decoration: none;
  color: ${textColors.secondaryLight};
`;

export const PdcIntroduction = () => (
  <div>
    <section>
      <DivWithGradient
        css={css`
          text-align: left;
          ${css({ ...adjustFontSizeTo(15) })};
          line-height: ${rhythm(0.7)};
        `}
      >
        <p>
          Kalkulator umożliwia ocenę, czy urządzenie ciśnieniowe podlega pod
          Dozór Techniczny oraz jakie są wymagane procedury oceny zgodności.
          Został przygotowany w oparciu o poniższe akty prawne:
        </p>

        <ul>
          <li>
            <DocuLink
              href="/Dyrektywa_w_sprawie_harmonizacji_ustawodawstw_państw_członkowskich__odnoszących_się_do_udostępniania__na__rynku__urządzeń__ciśnieniowych.pdf"
              target="_blank"
            >
              Dyrektywa Parlamentu Europejskiego i Rady 2014/68/UE z dnia 15
              maja 2014 r. w sprawie harmonizacji ustawodawstw państw
              członkowskich odnoszących się do udostępniania na rynku urządzeń
              ciśnieniowych,
            </DocuLink>
          </li>
          <li>
            <DocuLink
              href="/ROZPORZĄDZENIE_RADY_MINISTRÓW_w_sprawie_rodzajów_urządzeń_technicznych_podlegających_dozorowi_technicznemu.pdf"
              target="_blank"
            >
              Rozporządzenie Rady Ministrów z dnia 7 grudnia 2012 r. w sprawie
              rodzajów urządzeń technicznych , podlegających dozorowi
              technicznemu,
            </DocuLink>
          </li>
          <li>
            <DocuLink
              href="/ROZPORZĄDZENIE_MINISTRA_ROZWOJU_w_sprawie_prostych_zbiorników_ciśnieniowych.pdf"
              target="_blank"
            >
              Rozporządzenie Ministra Rozwoju z dnia 2 czerwca 2016 r. w sprawie
              prostych zbiorników ciśnieniowych,
            </DocuLink>
          </li>
          <li>
            <DocuLink
              href="/ROZPORZĄDZENIE_MINISTRA_ROZWOJU_w_sprawie_wymagań_dla_urządzeń_ciśnieniowych_i_zespołów_urządzeń_ciśnieniowych.pdf"
              target="_blank"
            >
              Rozporządzenie Ministra Rozwoju z dnia 11 lipca 2016 r. w sprawie
              wymagań dla urządzeń ciśnieniowych i zespołów urządzeń
              ciśnieniowych,
            </DocuLink>
          </li>
        </ul>

        <p>i nie dotyczy urządzeń nie objętych tymi aktami.</p>
        <br />
        <p>
          W przypadku zbiorników lub rurociągów, w których mogą wystąpić różne
          media, należy przeprowadzić procedurę klasyfikacji dla każdego medium
          oddzielnie, a następnie zaklasyfikować urządzenie do najwyżej
          kategorii. W ten sam sposób należy postępować, jeśli w zbiorniku
          obecne jest kilka różnych przestrzeni.
        </p>
        <br />
        <p>
          Uwaga! Kalkulator obejmuje jedynie wybrane fragmenty Dyrektywy
          2014/68/UE i rozporządzeń oraz spełnia jedynie rolę informacyjną. Mimo
          dołożenia wszelkich starań w procesie tworzenia kalkulatora, nie
          gwarantujemy poprawności wyników oraz nie ponosimy odpowiedzialności
          za użycie tego narzędzia.
        </p>
      </DivWithGradient>
    </section>

    <br />
    <h4
      css={css`
        text-align: center;
      `}
    >
      Aby zacząć, wybierz odpowiednią zakładkę w zależności od typu urządzenia:
    </h4>
  </div>
);
